<!--
 * @Author: lbh
 * @Date: 2021-09-23 10:22:35
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-26 16:36:18
 * @Description: file content
-->
<template>
  <div
    class="t-body home-box"
    :style="`min-height:${window.bodyH}px;`"
  >
    <el-backtop target=".s-body"></el-backtop>
    <el-backtop target="body"></el-backtop>
    <el-backtop target=".t-body"></el-backtop>
    <selfHeaderN :type="window.type" />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 'm-body_1'"
    >
      <!-- 輪播 -->
      <el-carousel
        indicator-position="outside"
        class="swiper"
        :style="`height:${window.swiperH}px`"
      >
        <el-carousel-item
          v-for="(item,index) in bannerImg"
          :key="index"
        >
          <el-image
            fit="cover"
            :src="item.img "
            style="width:100%;height:100%;"
            @click="go(index)"
          >
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>

      <productsItem
        :type="2"
        :windowW="window.w"
        :productsArr="productsArr"
      />
      <restaurantCase
        :widowW="window.w"
        :restaurantCase="restaurantCase"
      />

      <!-- 團隊優勢 -->
      <div
        class="team-advantage"
        :style="`background-image:url(${getImgPath('3pts_bg02.jpg')});`"
      >
        <div class="header">
          <div class="title">我們團隊的三大優勢</div>
          <div class="desc">您的智能餐飲專家</div>
        </div>
        <img :src="getImgPath('Blue-BG-01.jpg')" />
        <sButton
          text="立即聯絡我們"
          go="/contact"
          type="1"
          :index="1"
          style_="color:#FFF;border-color:rgba(255, 255, 255, 0.75);margin:30px 0 70px 0;"
        />
      </div>
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        :logo="graphic.logo"
        :bgImg="graphic.bgImg"
        :but="graphic.but"
        :button="graphic.button"
      />
      <!-- 合作商戶 -->
      <div
        v-if="1 == 2"
        class="partners"
        :style="`background-image:url(${getImgPath('footerline-1.png')})`"
      >
        <div class="header">
          <div class="title">合作商戶</div>
          <div class="desc">我們為每一位客戶提供量身定製的優質服務-無論大小</div>
        </div>
        <div class="imgs">
          <div
            v-for="(item,index) in partnersData"
            :key="index"
            class="img"
          >
            <img :src="item.img" />
          </div>

        </div>
      </div>
    </div>
    <selfFooter :type="window.type" />
    <self-contact />

  </div>
</template>

<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import productsItem from "@/components/commo/productsItem";
import restaurantCase from "@/components/commo/restaurantCase";
import selfGraphic from "@/components/commo/graphic";
import sButton from "@/components/commo/button";

import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    productsItem,
    restaurantCase,
    selfGraphic,
    sButton,
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        swiperH: 0,
        introduce_class: '',
        restaurant_class: '',
        w: 0
      },
      bannerImg: [], // banner 圖
      productsArr: [],
      restaurantCase: [], // 餐廳 案例
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        logo: '',
        bgImg: '',
        but: {
          bgImg: '',
          img: '',
          title: '',
          content: '',
          otherImage: ""
        },
        button: {
          index: 10,
          text: "關於Ricepon",
          go: "/about"
        }
      },
      partnersData: []
    }
  },
  mounted () {
    this.initWindow();
    util.setTitle('首頁 | 飯糰有限公司 Ricepon');
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 組裝banner 圖
    let bannerImg = [];
    bannerImg.push({
      img: util.getImgPath('banner5.png'),
    });
    // bannerImg.push({
    //   img: util.getImgPath('banner1-0x0.jpg'),
    // });
    bannerImg.push({
      img: util.getImgPath('banner2-2-0x0.png'),
    });

    this.bannerImg = bannerImg;

    // 組裝數據
    let productsArr = [
      { title: 'QR Code點餐', content: '自助點餐新趨勢 慳時間 慳人手 慳成本', img: util.getImgPath('QR_code_order-6.png'), go: '/contact', btnText: "了解更多" },
      // { title: 'RICEPON飯糰APP', content: '多功能的會員系統應用程式，自此餐廳跟客戶的關係更互動親近', img: util.getImgPath('pro05.jpg'), go: '/ricepon', btnText: "了解更多" },
      { title: '雲端餐飲系統', content: '即時數據更新 實時監控餐廳 您的智能管理工具', img: util.getImgPath('pro06.png'), go: '/contact', btnText: "了解更多" }];
    this.productsArr = productsArr;
    // 組裝 餐廳案列
    let restaurantCase = [
      { title: '人手不足，成本每季增加', content: '', img: util.getImgPath('accounting.png'), go: '/contact' },
      { title: '想與顧客關係更貼近', content: '', img: util.getImgPath('member.png'), go: '/contact' },
      { title: '溝通問題，經常落錯單', content: '', img: util.getImgPath('wrong_order.png'), go: '/contact' }];
    this.restaurantCase = restaurantCase;

    // 初始化數據
    this.graphic.but.bgImg = util.getImgPath('gingersoft_web_bg02.jpg');
    this.graphic.but.img = util.getImgPath('14631-Converted-01.png');
    // this.graphic.but.otherImage = util.getImgPath('cloud-1.jpg');
    this.graphic.but.title = 'Ricepon格言';
    this.graphic.but.content = '用研發人員的複雜，成就用戶的簡單！';
    this.graphic.button.text = "關於Ricepon"
    this.graphic.button.go = "/about";

    let partnersData = [
      { img: util.getImgPath('Beppulogo-1.jpg') },
      { img: util.getImgPath('hb07.jpg') },
      { img: util.getImgPath('Food-Story-logo.jpg') },
      { img: util.getImgPath('Star-Kitchen-logo.jpg') },
      { img: util.getImgPath('Whizbo-logo-1.jpg') },
      { img: util.getImgPath('lsj-logo.jpg') },
      { img: util.getImgPath('dxwLogo.jpg') },
      { img: util.getImgPath('dh-logo.jpg') },
      { img: util.getImgPath('sb-logo-1.jpg') },
      { img: util.getImgPath('Cestbon-logo.jpg') },
      { img: util.getImgPath('dgk-logo.jpg') },
      { img: util.getImgPath('ml-logo.jpg') },
    ];
    this.partnersData = partnersData;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.swiperH = parseInt(w / 2.744);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.introduce_class = 'introduce-app_2'
        this.window.restaurant_class = 'restaurant-case_2'
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.introduce_class = "introduce-app_1"
        this.window.restaurant_class = 'restaurant-case_1'
      } else {
        this.window.type = '1000';
        this.window.introduce_class = '';
        this.window.restaurant_class = ''
      }
    },
    getImgPath (img) {
      return util.getImgPath(img);
    },
    go (index) {
      if (index == 0) {
        this.$router.push({
          path: '/deliverySystem'
        })
      }
    }
  }

}
</script>

<style lang="less" scoped>
.home-box {
  .s-body {
    background-color: #f9f9f9;
    .swiper {
      & /deep/ .el-carousel__container {
        height: calc(100% - 30px);
      }
    }

    // 團隊優勢
    .team-advantage {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .header {
        padding: 50px 0;
        .title {
          font-family: Arial, sans-serif;
          text-transform: none;
          letter-spacing: 0;
          font-size: 36px;
          text-align: center;
          line-height: 34px;
          font-weight: 400;
          color: #fff;
        }
        .desc {
          text-transform: none;
          letter-spacing: 0;
          font-size: 20px;
          line-height: 34px;
          font-weight: 400;
          text-align: center;
          color: #fff;
          margin-top: 10px;
        }
      }
      img {
        margin: 0 50px;
        width: calc(100% - 100px);
      }
    }
    // 合作商戶
    .partners {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
      background-color: #fff;
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
        .title {
          font-family: Arial, sans-serif;
          text-transform: none;
          letter-spacing: 0;
          font-size: 36px;
          line-height: 34px;
          font-weight: 400;
        }
        .desc {
          color: #a9a9a9;
          margin-top: 10px;
        }
      }
      .imgs {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

// 媒體
@media only screen and (max-width: 600px) {
  .partners {
    padding: 50px 30px 150px !important;
    .imgs {
      .img {
        width: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        img {
          width: 30vw !important;
          height: 30vw !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .partners {
    padding: 100px 30px 150px;
    .imgs {
      width: 100%;
      margin: auto;
      .img {
        width: 33.33%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .partners {
    padding: 300px 30px 150px;
    .imgs {
      width: 1174px;
      margin: auto;
      .img {
        width: 179px;
        height: 179px;
        margin: 20px 0 0 20px;
        &:nth-of-type(1),
        &:nth-of-type(7) {
          margin-left: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>